import { Route, Routes } from "react-router-dom";
import "../App/App.css";
import Header from "../Header/Header";
import Main from "../Main/Main";
import Bars from "../Bars/Bars";
import AboutUs from "../AboutUs/AboutUs";
import Contacts from "../Contacts/Contacts";
import Footer from "../Footer/Footer";
import Privacy from "../Privacy/Privacy";
import Cookies from "../Cookies/Cookies";
import ScrollToTop from "../Scroll/Scroll";

function App() {
  return (
    <div className="page">
      <ScrollToTop/>
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/bars" element={<Bars />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/privacy" element={<Privacy />}/>
        <Route path="/cookies" element={<Cookies />}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
