import React from "react";

const Privacy = () => {
  return (
    <main className="privacy">
      <h3>Datenschutzrichtlinie für Gewinngalaxie.com</h3>
      <p className="t-17 privacy__mg">
        Bei Gewinngalaxie.com, die von Gewinngalaxie.com zugänglich ist, ist
        eine unserer Hauptprioritäten die Privatsphäre unserer Besucher. Dieses
        Dokument für Datenschutzrichtlinien enthält Arten von Informationen, die
        von Gewinngalaxie.com gesammelt und aufgezeichnet werden und wie wir sie
        verwenden.
      </p>
      <p className="t-17 privacy__mg">
        Wenn Sie zusätzliche Fragen haben oder weitere Informationen zu unseren
        Datenschutzrichtlinien benötigen, zögern Sie nicht, uns in Verbindung zu
        setzen.
      </p>
      <h3>Protokolldateien</h3>
      <p className="t-17 privacy__mg">
        Gewinngalaxie.com folgt einem Standardverfahren zur Verwendung von
        Protokolldateien. Diese Dateien protokollieren Besucher, wenn sie
        Websites besuchen. Alle Hosting -Unternehmen tun dies und Teil der
        Analysen von Hosting Services. Zu den von Protokolldateien gesammelten
        Informationen gehören Internet -Protokolladressen (IP), Browsertyp,
        Internetdienstanbieter (ISP), Datum und Zeitstempel, Überweisen/Beenden
        von Seiten und möglicherweise die Anzahl der Klicks. Diese sind nicht
        mit Informationen verknüpft, die persönlich identifizierbar sind. Der
        Zweck der Informationen besteht darin, Trends zu analysieren, die
        Website zu verwalten, die Bewegung der Benutzer auf der Website zu
        verfolgen und demografische Informationen zu sammeln. Unsere
        Datenschutzrichtlinie wurde mit Hilfe des Generators für
        Datenschutzrichtlinien erstellt.
      </p>
      <h3>Unsere Werbepartner</h3>
      <p className="t-17 privacy__mg">
        Einige Werbetreibende auf unserer Website verwenden möglicherweise
        Cookies und Web Beacons. Unsere Werbepartner sind unten aufgeführt.
        Jeder unserer Werbepartner hat seine eigenen Datenschutzrichtlinien für
        ihre Richtlinien für Benutzerdaten. Für den leichteren Zugang haben wir
        nach unten mit ihren Datenschutzrichtlinien verbunden.
      </p>
      <h3>Datenschutzrichtlinien</h3>
      <p className="t-17 privacy__mg">
        Sie können diese Liste konsultieren, um die Datenschutzrichtlinie für
        jeden der Werbepartner von Gewinngalaxie.com zu finden.
      </p>
      <p className="t-17 privacy__mg">
        Anzeigenserver oder Anzeigennetzwerke von Drittanbietern verwenden
        Technologien wie Cookies, JavaScript oder Web Beacons, die in ihren
        jeweiligen Anzeigen und Links verwendet werden, die auf
        Gewinngalaxie.com erscheinen, die direkt an den Benutzernbrowser
        gesendet werden. Sie erhalten Ihre IP -Adresse automatisch, wenn dies
        eintritt. Diese Technologien werden verwendet, um die Effektivität ihrer
        Werbekampagnen zu messen und/oder die von Ihnen besuchenden Websites auf
        Websites zu personalisieren.
      </p>
      <p className="t-17 privacy__mg">
        Beachten Sie, dass Gewinngalaxie.com keinen Zugriff auf diese Cookies
        hat oder auf diese Cookies kontrolliert wird, die von Werbetreibenden
        von Drittanbietern verwendet werden.
      </p>

      <h3>Datenschutzrichtlinien Dritter</h3>
      <p className="t-17 privacy__mg">
        Die Datenschutzrichtlinie von Gewinngalaxie.com gilt nicht für andere
        Werbetreibende oder Websites. Daher raten wir Ihnen, die jeweiligen
        Datenschutzrichtlinien dieser Anzeigenserver von Drittanbietern zu
        konsultieren, um detailliertere Informationen zu erhalten. Es kann deren
        Praktiken und Anweisungen zur Abmeldung bestimmter Optionen enthalten.
      </p>
      <p className="t-17 privacy__mg">
        Sie können Cookies über Ihre individuellen Browseroptionen deaktivieren.
        Um detailliertere Informationen über das Cookie -Management mit
        bestimmten Webbrowsern zu kennen, finden Sie auf den jeweiligen Websites
        der Browser. Was sind Kekse?
      </p>
      <h3>Kinderinformationen</h3>
      <p className="t-17 privacy__mg">
        Ein weiterer Teil unserer Priorität ist der Schutz für Kinder bei der
        Nutzung des Internets. Wir ermutigen Eltern und Erziehungsberechtigte,
        ihre Online -Aktivitäten zu beobachten, daran teilzunehmen und/oder zu
        überwachen und zu leiten.
      </p>
      <p className="t-17 privacy__mg">
        Gewinngalaxie.com sammelt nicht wissentlich persönliche identifizierbare
        Informationen von Kindern unter 13 Jahren Entfernen Sie solche
        Informationen umgehend aus unseren Aufzeichnungen.
      </p>
      <h3>Nur Online -Datenschutzrichtlinie</h3>
      <p className="t-17 privacy__mg">
        Diese Datenschutzrichtlinie gilt nur für unsere Online -Aktivitäten und
        gilt für Besucher unserer Website in Bezug auf die Informationen, die
        sie in Gewinngalaxie.com geteilt und/oder gesammelt haben. Diese
        Richtlinie gilt nicht für Informationen, die offline oder über andere
        Kanäle als diese Website gesammelt wurden.
      </p>
      <h3>Zustimmung</h3>
      <p className="t-17 privacy__mg">
        Durch die Nutzung unserer Website stimmen Sie hiermit unseren
        Datenschutzrichtlinien zu und stimmen ihren Geschäftsbedingungen zu.
      </p>
    </main>
  );
};

export default Privacy;
