import React, { useState } from "react";
import axios from "axios";
import "./Contacts.css";
import img from "../../images/contacts-pic.webp";

const Contacts = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post('mail.php', formData)
      .then(response => {
        console.log('Message send');
      })
      .catch(error => {
        console.log('Error sending message');
      });
  };

  return (
    <section className="contacts bg-gr">
      <form onSubmit={handleSubmit} className="contacts__form">
        <h2 className="contacts__header">Kontakte</h2>
        <p className="t-23 contacts__paragraph">
          Füllen Sie das Formular aus und wir werden Sie kontaktieren, um Ihnen
          mehr über jede Balken zu erzählen
        </p>
        <div className="form__input-container">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Ihr Name..."
            className="form__input t-17"
            required
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Deine E-Mail..."
            className="form__input t-17"
          />
        </div>
        <button type="submit" className="btn form__btn t-17">
          Schicken
        </button>
      </form>
      <div className="contacts__image-container">
        <img
          src={img}
          alt="people having fun"
          width="416"
          height="463"
          className="contacts__img"
        />
      </div>
    </section>
  );
};

export default Contacts;
