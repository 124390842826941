import React from "react";
import "./Footer.css";
import age from "../../images/age.svg";
import telegram from "../../images/telegram.svg";
import inst from "../../images/inst.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__el1">
        <p className="t-14">
          Wenn Sie mit uns Kontakt aufnehmen oder uns Feedback senden möchten,
          zögern Sie bitte nicht! Sie können uns per E-Mail kontaktieren.
        </p>
        <a
          href="mailto:gewinngalaxie@gmail.com"
          className="t-17 footer__mail-link"
          target="_blank"
          rel="noreferrer"
        >
          gewinngalaxie@gmail.com
        </a>
      </div>
      <div className="footer__el2">
        <p className="t-14">
          Alle Spiele werden ausschließlich im Rahmen des Unterhaltungsprogramms
          gespielt und beinhalten keine materiellen Gewinne. Die Teilnahme an
          der Veranstaltung beinhaltet lediglich eine symbolische Belohnung bzw.
          einen Preis, den die Einrichtung als Dankeschön für die Teilnahme
          bereitstellt.
        </p>
        <p className="t-17 opacity">
          „gewinngalaxie.com“ verwendet Cookies, um das beste Erlebnis zu
          bieten. Indem Sie auf der Website bleiben, stimmen Sie der Verwendung
          von Cookies zu.
        </p>
      </div>
      <Link to="/cookies" className="footer__el3 t-14">
        Ausführlicher
      </Link>
      <div className="footer__link-container footer__el4">
        <img
          src={age}
          alt="age limit 18+"
          className="footer__age"
          width="64"
          height="64"
        />
        <a href="#">
          <img src={telegram} alt="telegram icon" width="32" height="32" />
        </a>
        <a href="#">
          <img src={inst} alt="instagram icon" width="32" height="32" />
        </a>
      </div>
      <Link to="/privacy" className="t-17 footer__el5">
        Datenschutzrichtlinie
      </Link>
    </footer>
  );
};

export default Footer;
