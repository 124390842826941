import React, { useState } from "react";
import "./Bars.css";
import barOne from "../../images/bar-1-1.webp";
import barOneAdd from "../../images/bar-1-2.webp";
import barTwo from "../../images/bar-2-1.webp";
import barTwoAdd from "../../images/bar-2-2.webp";
import barThree from "../../images/bar-3-1.webp";
import barThreeAdd from "../../images/bar-3-2.webp";
import barFour from "../../images/bar-4-1.webp";
import barFourAdd from "../../images/bar-4-2.webp";
import star from "../../images/star.svg";
import pin from "../../images/pin.svg";

const Bars = () => {
  const [isActiveBarOne, setIsActiveBarOne] = useState(false);
  const [isActiveBarTwo, setIsActiveBarTwo] = useState(false);
  const [isActiveBarThree, setIsActiveBarThree] = useState(false);
  const [isActiveBarFour, setIsActiveBarFour] = useState(false);

  return (
    <section className="bars">
      <h2>Sportbars</h2>
      <div className="bars__container">
        <div className="bar bar-one">
          <div className="bar__header-container bg-gr">
            <h3 className="bar__header">
              1. Meeting point 25 • Cocktail - Cafe - Bar
            </h3>
          </div>
          <div className="bar__info">
            <div className="description__first">
              <div className="bar__logo">
                <img
                  src={barOne}
                  alt="bar logo"
                  width="394"
                  height="280"
                  className="bar__logo-img"
                />
                <div className="bar__adress">
                  <img src={pin} alt="pin" />
                  <p className="t-14">Gleimstraße 25, 10437, Berlin</p>
                </div>
              </div>
              <div className="bar__description">
                <div className="bar__rating">
                  <img src={star} alt="star" />
                  <p className="t-17">4.5</p>
                </div>
                <p className="t-17">
                  Treffpunkt 25 ist eine lebende und freundliche Sportbar in
                  Deutschland, die Ihnen nicht nur die ersten Übertragungen
                  Ihrer Lieblingsspiele, sondern auch eine große Auswahl an
                  Cocktails, köstlichem Essen, aufregende Spiele auf
                  Spielautomaten und die Fähigkeit, Sportwetten herzustellen .
                </p>
              </div>
              <div className="bar__activity">
                <p className="t-23">Aktivität</p>
                <ul className="bar__activity-container">
                  <li className="bar__activity-item">
                    <div className="bar__activity-item_name bg-gr t-17">
                      "Game Night"
                    </div>
                    <p className="t-17">
                      Wochenende mit Brettspielturnieren, einschließlich Darts,
                      Desktop -Fußball und Karten.
                    </p>
                  </li>
                  <li className="bar__activity-item">
                    <div className="bar__activity-item_name bg-gr t-17">
                      "Quiz Night"
                    </div>
                    <p className="t-17">
                      Abend mit Quiz zu einem Sportthema mit wertvollen Preisen
                      für Gewinner.
                    </p>
                  </li>
                  <li className="bar__activity-item">
                    <div className="bar__activity-item_name bg-gr t-17">
                      "Live-Musik"
                    </div>
                    <p className="t-17">
                      Auftritte von Live-Musik im Stil von Rock und Pop.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            {isActiveBarOne && (
              <div className="description__second">
                <div className="foosball">
                  <p className="t-23">Tischfußballwettbewerbe</p>
                  <ul className="foosball__list">
                    <li className="t-17 foosball__list-item list-item">
                      Wöchentliche Tischfußballturniere mit Preisen für die
                      besten Spieler.
                    </li>
                    <li className="t-17 foosball__list-item list-item">
                      Die Gelegenheit, ein Team zu schaffen und unter anderen
                      Desktop -Fußballliebhabern an einem Turnier teilzunehmen.
                    </li>
                    <li className="t-17 foosball__list-item list-item">
                      Sichere und komfortable Bedingungen für das Spiel.
                    </li>
                  </ul>
                </div>
                <div className="slots">
                  <p className="t-23">Spielautomaten</p>
                  <ul className="slots__list">
                    <li className="t-17 slots__list-item list-item">
                      Moderne Spielautomaten mit einer großen Auswahl an Spielen
                      und Bonusfunktionen.
                    </li>
                    <li className="t-17 slots__list-item list-item">
                      Die Fähigkeit, heiße Preise und Boni zu gewinnen.
                    </li>
                    <li className="t-17 slots__list-item list-item">
                      Sichere und zertifizierte Spielautomaten.
                    </li>
                  </ul>
                </div>
                <div className="description__img-container">
                  <img src={barOneAdd} alt="bar" className="description__img" />
                </div>
              </div>
            )}

            <div className="bar__btn-container">
              <button
                className={`btn activate-btn t-17 ${
                  isActiveBarOne ? "arrow" : ""
                }`}
                onClick={() => setIsActiveBarOne(!isActiveBarOne)}
              >
                {isActiveBarOne ? "Drehen" : "Mehr"}
              </button>
              <a
                href="https://treffpunkt25.de/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn book-btn bg-yel t-17"
              >
                Buch
              </a>
            </div>
          </div>
        </div>
        <div className="bar bar-two">
          <div className="bar__header-container bg-gr">
            <h3 className="bar__header">2. Belushi's Berlin</h3>
          </div>
          <div className="bar__info">
            <div className="description__first">
              <div className="bar__logo">
                <img
                  src={barTwo}
                  alt="bar logo"
                  width="394"
                  height="280"
                  className="bar__logo-img"
                />
                <div className="bar__adress">
                  <img src={pin} alt="pin" />
                  <p className="t-14">
                    Rosa-Luxemburg-Platz
                    <br></br>Rosa-Luxemburg-Straße 41, Berlin, Deutschland
                  </p>
                </div>
              </div>
              <div className="bar__description">
                <div className="bar__rating">
                  <img src={star} alt="star" />
                  <p className="t-17">4.2</p>
                </div>
                <p className="t-17">
                  Belushis Berlin ist eine helle und fröhliche Sportbar im
                  Herzen von Berlin, die Ihnen nicht nur die ersten Sendungen
                  heißer Spiele, sondern auch eine große Auswahl an köstlichen
                  Gerichten und Getränken, lustigen Spielen auf Spielautomaten
                  und die Möglichkeit, zu stürzen Die Atmosphäre der Aufregung
                  mit Freunden.
                </p>
              </div>
              <div className="bar__activity">
                <p className="t-23">Aktivität</p>
                <ul className="bar__activity-container">
                  <li className="bar__activity-item">
                    <div className="bar__activity-item_name bg-gr t-17">
                      "Quiz Night"
                    </div>
                    <p className="t-17">
                      Wochenabende mit lustigen Quiz zu einem Sportthema mit
                      wertvollen Preisen für Gewinner und der Möglichkeit, ihr
                      Wissen zu zeigen.
                    </p>
                  </li>
                  <li className="bar__activity-item">
                    <div className="bar__activity-item_name bg-gr t-17">
                      "Live-Musik"
                    </div>
                    <p className="t-17">
                      Auftritte von Live -Musik im Stil von Rock and Pop, um
                      eine lebendige und energiegeladene Atmosphäre zu schaffen.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            {isActiveBarTwo && (
              <div className="description__second">
                <div className="foosball">
                  <p className="t-23">Tischfußballwettbewerbe</p>
                  <ul className="foosball__list">
                    <li className="t-17 foosball__list-item list-item">
                      Wöchentliche Tischfußballturniere mit Preisen für die
                      besten Spieler, um ihre Fähigkeiten zu überprüfen und mit
                      Freunden zu konkurrieren.
                    </li>
                    <li className="t-17 foosball__list-item list-item">
                      Die Gelegenheit, ein Team zu schaffen und unter anderen
                      Liebhabern des Tischfußballs an einem Turnier
                      teilzunehmen, um den Geist eines Teamspiels zu spüren.
                    </li>
                  </ul>
                </div>
                <div className="slots">
                  <p className="t-23">Spielautomaten</p>
                  <ul className="slots__list">
                    <li className="t-17 slots__list-item list-item">
                      Moderne Spielautomaten mit einer großen Auswahl an Spielen
                      und Bonusfunktionen, um Ihr Glück zu testen und das
                      maximale Vergnügen zu erhalten.
                    </li>
                    <li className="t-17 slots__list-item list-item">
                      Die Fähigkeit, heiße Preise und Boni zu gewinnen, um Ihr
                      Spiel noch spannender zu machen.
                    </li>
                  </ul>
                </div>
                <div className="description__img-container">
                  <img src={barTwoAdd} alt="bar" className="description__img" />
                </div>
              </div>
            )}

            <div className="bar__btn-container">
              <button
                className={`btn activate-btn t-17 ${
                  isActiveBarTwo ? "arrow" : ""
                }`}
                onClick={() => setIsActiveBarTwo(!isActiveBarTwo)}
              >
                {isActiveBarTwo ? "Drehen" : "Mehr"}
              </button>
              <a
                href="https://www.tripadvisor.de/Restaurant_Review-g187323-d1341597-Reviews-Belushi_s-Berlin.html"
                target="_blank"
                rel="noopener noreferrer"
                className="btn book-btn bg-yel t-17"
              >
                Buch
              </a>
            </div>
          </div>
        </div>
        <div className="bar bar-three">
          <div className="bar__header-container bg-gr">
            <h3 className="bar__header">3. Salamas Bar</h3>
          </div>
          <div className="bar__info">
            <div className="description__first">
              <div className="bar__logo">
                <img
                  src={barThree}
                  alt="bar logo"
                  width="394"
                  height="280"
                  className="bar__logo-img"
                />
                <div className="bar__adress">
                  <img src={pin} alt="pin" />
                  <p className="t-14">
                    Samariterstraße
                    <br></br>Niederbarnimstraße 24, Berlin, Deutschland
                  </p>
                </div>
              </div>
              <div className="bar__description">
                <div className="bar__rating">
                  <img src={star} alt="star" />
                  <p className="t-17">4.6</p>
                </div>
                <p className="t-17">
                  Salamas Bar ist eine lebhafte und energiegeladene Sportbar in
                  Deutschland, die Ihnen nicht nur die ersten Übertragungen
                  heißer Spiele, sondern auch köstliches Essen, eine große
                  Auswahl an Getränken, lustige Spiele auf Spielautomaten und
                  die Fähigkeit, in die Atmosphäre von zu stürzen Aufregung mit
                  Freunden.
                </p>
              </div>
              <div className="bar__activity">
                <p className="t-23">Aktivität</p>
                <ul className="bar__activity-container">
                  <li className="bar__activity-item">
                    <div className="bar__activity-item_name bg-gr t-17">
                      "Karaoke Night"
                    </div>
                    <p className="t-17">
                      Weekly Abende mit Karaoke, wo jeder seine Gesangstalente
                      zeigen und sich wie ein echter Star fühlen kann.
                    </p>
                  </li>
                  <li className="bar__activity-item">
                    <div className="bar__activity-item_name bg-gr t-17">
                      "Themenabende"
                    </div>
                    <p className="t-17">
                      thematische Abende mit unterschiedlichen Werbeaktionen und
                      Angeboten zum Beispiel "Beer Evening", "Cocktailabend"
                      oder "Abend der Sportspiele".
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            {isActiveBarThree && (
              <div className="description__second">
                <div className="foosball">
                  <p className="t-23">Tischfußballwettbewerbe</p>
                  <ul className="foosball__list">
                    <li className="t-17 foosball__list-item list-item">
                      Wöchentliche Tischfußballturniere mit Preisen für die
                      besten Spieler, um ihre Fähigkeiten zu überprüfen und mit
                      Freunden zu konkurrieren.
                    </li>
                    <li className="t-17 foosball__list-item list-item">
                      Die Gelegenheit, ein Team zu schaffen und unter anderen
                      Liebhabern des Tischfußballs an einem Turnier
                      teilzunehmen, um den Geist eines Teamspiels zu spüren.
                    </li>
                  </ul>
                </div>
                <div className="slots">
                  <p className="t-23">Spielautomaten</p>
                  <ul className="slots__list">
                    <li className="t-17 slots__list-item list-item">
                      Die Fähigkeit, heiße Preise und Boni zu gewinnen, um Ihr
                      Spiel noch spannender zu machen.
                    </li>
                    <li className="t-17 slots__list-item list-item">
                      Sichere und zertifizierte Spielautomaten, damit Sie mit
                      vollem Ruhe und Selbstvertrauen spielen können.
                    </li>
                  </ul>
                </div>
                <div className="description__img-container">
                  <img
                    src={barThreeAdd}
                    alt="bar"
                    className="description__img"
                  />
                </div>
              </div>
            )}

            <div className="bar__btn-container">
              <button
                className={`btn activate-btn t-17 ${
                  isActiveBarThree ? "arrow" : ""
                }`}
                onClick={() => setIsActiveBarThree(!isActiveBarThree)}
              >
                {isActiveBarThree ? "Drehen" : "Mehr"}
              </button>
              <a
                href="https://www.tripadvisor.de/Attraction_Review-g187323-d12172074-Reviews-Salamas_Bar-Berlin.html"
                target="_blank"
                rel="noopener noreferrer"
                className="btn book-btn bg-yel t-17"
              >
                Buch
              </a>
            </div>
          </div>
        </div>
        <div className="bar bar-four">
          <div className="bar__header-container bg-gr">
            <h3 className="bar__header">4. Wilma Restaurant & Cafe</h3>
          </div>
          <div className="bar__info">
            <div className="description__first">
              <div className="bar__logo">
                <img
                  src={barFour}
                  alt="bar logo"
                  width="394"
                  height="280"
                  className="bar__logo-img"
                />
                <div className="bar__adress">
                  <img src={pin} alt="pin" />
                  <p className="t-14">
                    Blissestrasse
                    <br></br>Str. Am Schoelerpark 39, Berlin, Deutschland
                  </p>
                </div>
              </div>
              <div className="bar__description">
                <div className="bar__rating">
                  <img src={star} alt="star" />
                  <p className="t-17">5.0</p>
                </div>
                <p className="t-17">
                  Das Wilma Restaurant & Cafe ist ein gemütlicher und
                  freundlicher Ort in Deutschland, an dem Sie köstliche Essen,
                  heiße Sportsendungen und faszinierende Spiele genießen können.
                  Hier finden Sie die ersten Übertragungen Ihrer
                  Lieblingsspiele, eine große Auswahl an Gerichten und Getränken
                  sowie die Möglichkeit, Brettspiele und Spielautomaten zu
                  spielen.
                </p>
              </div>
              <div className="bar__activity">
                <p className="t-23">Aktivität</p>
                <ul className="bar__activity-container">
                  <li className="bar__activity-item">
                    <div className="bar__activity-item_name bg-gr t-17">
                      "Live -Musik"
                    </div>
                    <p className="t-17">
                      Auftritte von Live -Musik im Stil von Jazz und Blues, um
                      eine Atmosphäre der Entspannung und einen gemütlichen
                      Abend zu schaffen.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            {isActiveBarFour && (
              <div className="description__second">
                <div className="foosball">
                  <p className="t-23">Tischfußballwettbewerbe</p>
                  <ul className="foosball__list">
                    <li className="t-17 foosball__list-item list-item">
                      Wöchentliche Tischfußballturniere mit Preisen für die
                      besten Spieler, um ihre Fähigkeiten zu überprüfen und mit
                      Freunden zu konkurrieren.
                    </li>
                    <li className="t-17 foosball__list-item list-item">
                      Sichere und komfortable Bedingungen für das Spiel, damit
                      Sie das Spiel in voller Kraft genießen können.
                    </li>
                  </ul>
                </div>
                <div className="slots">
                  <p className="t-23">Spielautomaten</p>
                  <ul className="slots__list">
                    <li className="t-17 slots__list-item list-item">
                      Moderne Spielautomaten mit einer großen Auswahl an Spielen
                      und Bonusfunktionen, um Ihr Glück zu testen und das
                      maximale Vergnügen zu erhalten.
                    </li>
                    <li className="t-17 slots__list-item list-item">
                      Die Fähigkeit, heiße Preise und Boni zu gewinnen, um Ihr
                      Spiel noch spannender zu machen.
                    </li>
                  </ul>
                </div>
                <div className="description__img-container">
                  <img
                    src={barFourAdd}
                    alt="bar"
                    className="description__img"
                  />
                </div>
              </div>
            )}

            <div className="bar__btn-container">
              <button
                className={`btn activate-btn t-17 ${
                  isActiveBarFour ? "arrow" : ""
                }`}
                onClick={() => setIsActiveBarFour(!isActiveBarFour)}
              >
                {isActiveBarFour ? "Drehen" : "Mehr"}
              </button>
              <a
                href="https://www.tripadvisor.de/Restaurant_Review-g187323-d15841121-Reviews-Wilma_Sportbar-Berlin.html"
                target="_blank"
                rel="noopener noreferrer"
                className="btn book-btn bg-yel t-17"
              >
                Buch
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Bars;
