import React from "react";
import "./AboutUs.css";
import imgOne from "../../images/img1.webp";
import imgTwo from "../../images/img2.webp";
import imgThree from "../../images/img3.webp";

const AboutUs = () => {
  return (
    <section className="about-us pd-bot">
      <div className="about__text-container">
        <div className="about__header-container">
          <h2 className="col-w">Über uns</h2>
          <h3 className="col-gr">
            Unsere Website ist Ihr verlässlicher Wegweiser durch die Welt der
            Sportsbars in Deutschland.
          </h3>
          <p className="t-17 about__paragraph">
            Möchten Sie heiße Spiele genießen, in die Atmosphäre der Aufregung
            und des Vergnügens beim Spielen von Spielautomaten eintauchen?
            Unsere Website ist ein idealer Ort für diejenigen, die Sport,
            Casinos und gute Gesellschaft lieben! Wir bieten Ihnen eine
            vollständige Liste der Sportbars in Deutschland, in denen Sie die
            allerersten Übertragungen Ihrer Lieblingsspiele verfolgen,
            Spielautomaten spielen und Sportwetten abschließen können.
          </p>
        </div>
      </div>
      <div className="about__image-container">
        <div className="about__img-1">
          <img
            src={imgOne}
            alt="people having fun"
            width="304"
            height="222"
            className="about__image"
          />
        </div>
        <div className="about__img-2">
          <img
            src={imgTwo}
            alt="people having fun"
            width="416"
            height="308"
            className="about__image"
          />
        </div>
        <div className="about__img-3">
          <img
            src={imgThree}
            alt="people having fun"
            width="416"
            height="266"
            className="about__image"
          />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
