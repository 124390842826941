import React from "react";
import "./Main.css";
import AboutUs from "../AboutUs/AboutUs";
import Bars from "../Bars/Bars";
import Contacts from "../Contacts/Contacts";
import mainCover from "../../images/main-cover.webp";

const MainCover = () => {
  return (
    <main className="main">
      <section className="main__cover">
        <div className="main__text-container">
          <div className="main__header-container">
            <h1 className="col-gr">Top 4 der besten Sportbars in</h1>
            <p className="t-23 main__paragraph">
              Deine Lieblings-Sportbars in Deutschland mit Spielautomaten und
              Sportwetten: alles an einem Ort!
            </p>
          </div>
          <ul className="main__description bg-gr">
            <li className="description__item t-23">
              Sichere und zuverlässige Daten zu jedem Balken.
            </li>
            <li className="description__item t-23">
              Bequeme Suche nach Stadt, Sportart und Spiel.
            </li>
          </ul>
        </div>
        <div className="main__image-container">
          <img
            src={mainCover}
            alt="people having fun"
            width="704"
            height="679"
            className="main__image"
          />
        </div>
      </section>
      <AboutUs/>
      <Bars/>
      <Contacts/>
    </main>
  );
};

export default MainCover;
