import { Link } from "react-router-dom";
import "./Header.css";
import burgerMenu from "../../images/burger-menu.svg";
import closeBtn from "../../images/close-btn.svg";
import { useState } from "react";

function Header() {
  const [isActive, setIsActive] = useState(false);

  const handleLinkClick = () => {
    setIsActive(false);
  };

  return (
    <div className="header__container">
      <div className="header__link-container">
        <Link to="/" className="header__link t-17">
          Hauptseite
        </Link>
        <Link to="/about-us" className="header__link t-17">
          Über uns
        </Link>
        <Link to="/bars" className="header__link t-17">
          Sportbars
        </Link>
        <Link to="/contacts" className="header__link t-17">
          Kontakte
        </Link>
      </div>

      <div className="burger-menu">
        <div id="burger-menu">
          <img
            src={burgerMenu}
            alt="burger menu icon"
            className="burger__icon"
            onClick={() => setIsActive(true)}
          />
        </div>
        <nav
          className={`burger-menu__nav ${isActive ? "active" : ""}`}
          id="nav"
        >
          <img
            src={closeBtn}
            className="burger__close-btn"
            id="close-icon"
            alt="close menu icon"
            onClick={() => setIsActive(false)}
          />
          <ul className="burger-menu__nav-container">
            <li className="burger-menu__nav-link">
              <Link
                to="/"
                className="header__link t-17"
                onClick={handleLinkClick}
              >
                Hauptseite
              </Link>
            </li>
            <li className="burger-menu__nav-link">
              <Link
                to="/about-us"
                className="header__link t-17"
                onClick={handleLinkClick}
              >
                Über uns
              </Link>
            </li>
            <li className="burger-menu__nav-link">
              <Link
                to="/bars"
                className="header__link t-17"
                onClick={handleLinkClick}
              >
                Sportbars
              </Link>
            </li>
            <li className="burger-menu__nav-link">
              <Link
                to="/contacts"
                className="header__link t-17"
                onClick={handleLinkClick}
              >
                Kontakte
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Header;
